html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevents horizontal scroll bar */
}
.containerFluidForPadding{
  padding-left: 55px;
  padding-right: 55px;
  font-family: "Hind", sans-serif;
  font-style: normal;
}
ul li{
  list-style: none;
}



/* the below is also applied applied for home.js code STARTS  */

.bulbSpan img{
  width: 22px;
  margin-right: 10px;
}
.blueText{
  color: #553cdf;
  font-weight: 500;
  line-height: 26px;
}
.gateWaytitle{
  font-size: 40px!important;
  font-weight: 600;
  color: #221859;
}

.greyText{
  color: gray;
  font-weight: 500;
  font-size: 16px;
}
/* the above is also applied applied for home.js code ends  */



.ArrowBtn{
  place-content: center;
}
.ArrowBtn button{
  background-color: #553cdf;
  color: white;
  height: 45px;
  max-width: 168px;
}
.ArrowBtn button:hover{
  background-color: white;
  color: #553cdf;
  border:1px solid #553cdf;
}
.ArrowBtn button:active{
  color: #553cdf;
}



/* media queries for external parts like container size 
copy right  ,sticky navbar , moving on hovers  */

@media (max-width:325px){
  
  .containerFluidForPadding{
    padding-left: 10px!important;
    padding-right: 10px!important;
  }
  

} 
@media(max-width:325px){
  .containerFluidForPadding{
    padding-left: 30px!important;
    padding-right: 30px!important;
  }
}

  @media(min-width:325px) and (max-width:768px){
    .containerFluidForPadding{
      padding-left: 25px!important;
      padding-right: 25px!important;
    }

  }
  @media (max-width:768px) {
    .gateWaytitle{
      font-size: 24px!important;
    }
  }

  /* home container hover starts */
  .main-container , #topCategoryParallex{
    position: relative;
  z-index: 100; 
  }
.main-container .objectOne{
  position: absolute;
  top: 300px;
  left: -32px;
  z-index: 500;
 }
 .main-container .objectTwo{
  width: 50px;
  position: absolute;
  top: 25px;
  left: 800px;
  z-index: 500;
 }
 .main-container .objectThree{
  width: 50px;
  position: absolute;
  top: 30px;
  right: 100px;
  z-index: 500;
 }
.forHoverEffect ,.fourStepsHover , #carouselDot ,.footerHover{
  position: relative;
  z-index: 200;
}
  /* home container hover ends */


  /* topCategoryParallex container hover starts */


#topCategoryParallex .objectOne{
  position: absolute;
  top: 100px;
  left: 10px;
  z-index: 500;
 }
 #topCategoryParallex .objectTwo{
  width: 50px;
  position: absolute;
  top: -50%;
  left: 30%;
  z-index: 500;
 }
 #topCategoryParallex .objectThree{
  width: 50px;
  position: absolute;
  top: -50%;
  right: 0px;
  z-index: 500;
 }


  /* topCatogery container hover ends */

  /* circle Animation hover Starts */
  #circleAnimationHover{
    position: relative;
    z-index: 200;
  }
  #circleAnimationHover .objectOne{
    width: 50px;
    position: absolute;
    top: 50%;
    left: 10%;
    z-index: 500;
  }

  #circleAnimationHover .objectTwo{
    width: 50px;
    position: absolute;
    top: 10%;
    left: 36%;
    z-index: 500;
   }


   /* fourStep obj code */
   .fourStepsHover .objectOne{
    width: 50px;
    position: absolute;
    top: 50%;
    left: 5%;
    z-index: 500;
   }

   .fourStepsHover .objectTwo{
    width: 30px;
    position: absolute;
    top: 45%;
    right: 50px;
    z-index: 500;
   }

   /* courser object */

   #carouselDot .objectOne{
    width: 50px;
    position: absolute;
    top: 30%;
    right: 15%;
    z-index: 500;
   }

   #carouselDot .objectTwo{
    width: 70px;
    position: absolute;
    top: 55%;
    right: 8%;
    z-index: 500;
   }

   .footerHover .objectOne{
    width: 50px;
    position: absolute;
    top: 15%;
    left: 5%;
    z-index: 500;
   }

   .footerHover .objectTwo{
    width: 50px;
    position: absolute;
    top: 15%;
    right: 30%;
    z-index: 500;
   }


@media (max-width:320px){
.main-container .objectOne{
  display: none;
 }
 .main-container .objectTwo{
  left: 0px!important;
 }
 .main-container .objectThree{
  display: none;

 }
 
 #topCategoryParallex .objectTwo{
  left: 0px;
 }
 #topCategoryParallex .objectThree{
  display: none;

 }

 #circleAnimationHover .objectTwo{
  display: none; 
}
 
}


@media (min-width:320.6px) and (max-width:600px){
  .main-container .objectOne{
    display: none;
   }
   .main-container .objectTwo{
  left: 0px!important;
   }
   .main-container .objectThree{
    display: none;
  
   }
  #circleAnimationHover .objectTwo{
    display: none;
  }
  .footerHover .objectTwo{
    top: 15%!important;
    right: 10%;
   }
  }
  @media (max-width:576px) {
    #carouselDot .objectOne, #carouselDot .objectTwo{
      display: none;
    }
  }
  
  @media (max-width:768px) {
   .footerHover .objectTwo{
    top: 20%;
   }
  }
@media (min-width:600.6px) and  (max-width:768px){
  .main-container .objectOne{
    display: none;
   }
   .main-container .objectTwo{
    left: 0px!important;
   }
   #topCategoryParallex .objectTwo{
    display: none;
   }
   #topCategoryParallex .objectThree{
    display: none;
  
   }
  #circleAnimationHover .objectTwo{
    display: none;
  }
   
  }

  
@media (min-width:768.6px) and (max-width:992px){
  
   .main-container .objectTwo{
    left: 400px;
   }
  }

    
@media (max-width:1152px){
  
  .main-container .objectTwo{
   left: 450px;
  }
  
 }