.loader {
    border: 6px solid white;
    border-radius: 50%;
    border-top: 6px solid #553cdf;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 1.4s linear infinite;
    animation: spin 1.4s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }